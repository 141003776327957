import { User as RealmUser } from 'realm-web';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { ICampanha } from '../modelos/ICampanha';
import { FarmaError } from 'src/integracoes/modelos/erros';

// ------------------------ OBTEM CAMPANHA POR ID ------------------------ //
const obtem = async (
  usuarioMongoAppServices: RealmUser,
  id: string
): Promise<IRespostaFuncao<ICampanha>> => {
  return usuarioMongoAppServices.functions['empresas/lojas/campanhas/obtemCampanha']({ id })
    .then((resultado) => resultado as IRespostaFuncao<ICampanha>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter dados da Campanha',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ CRIAR CAMPANHA ------------------------ //
const cria = async (
  usuarioMongoAppServices: RealmUser,
  campanha: ICampanha
): Promise<IRespostaFuncao<ICampanha>> => {
  return usuarioMongoAppServices.functions['empresas/lojas/campanhas/criaCampanha'](campanha)
    .then((resultado) => resultado as IRespostaFuncao<ICampanha>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao criar Campanha',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ ATUALIZAR CAMPANHA ------------------------ //
const atualiza = async (
  usuarioMongoAppServices: RealmUser,
  campanha: ICampanha
): Promise<IRespostaFuncao<ICampanha>> => {
  return usuarioMongoAppServices.functions['empresas/lojas/campanhas/atualizaCampanha'](campanha)
    .then((resultado) => resultado as IRespostaFuncao<ICampanha>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atualizar dados da Campanha',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ DUPLICAR CAMPANHA ------------------------ //
const duplica = async (
  usuarioMongoAppServices: RealmUser,
  id: string
): Promise<IRespostaFuncao<ICampanha>> => {
  return usuarioMongoAppServices.functions['empresas/lojas/campanhas/duplicaCampanha']({ id })
    .then((resultado) => resultado as IRespostaFuncao<ICampanha>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao duplicar Campanha',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ DELETAR CAMPANHA ------------------------ //
const deleta = async (
  usuarioMongoAppServices: RealmUser,
  id: string
): Promise<IRespostaFuncao<ICampanha>> => {
  return usuarioMongoAppServices.functions['empresas/lojas/campanhas/removeCampanha']({ id })
    .then((resultado) => resultado as IRespostaFuncao<ICampanha>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao remover Campanha',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

export const Campanhas = {
  obtem,
  cria,
  atualiza,
  duplica,
  deleta
};
