import { User as RealmUser } from 'realm-web';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { IUsuarioMongoDB } from '../../../modelos/usuarios';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { IUsuarioFormulario } from 'src/integracoes/modelos/usuarios';
import { converteUsuarioFormularioEmUsuarioMongoDB } from '../utils';
import { IPapel } from '../../../modelos/usuarios';
import { IpainelUsuario } from 'src/integracoes/modelos/painel';

// ------------------------ OBTEM DADOS USUÁRIO ATUAL ------------------------ //
const obtem = async (
  usuarioMongoAppServices: RealmUser
): Promise<IRespostaFuncao<IUsuarioMongoDB>> => {
  return usuarioMongoAppServices.functions['empresas/usuarios/obtemUsuario']()
    .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter dados do Usuário',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ CRIAR USUÁRIO ------------------------ //
const cria = async (
    usuarioMongoAppServices: RealmUser,
    usuarioFormulario: IUsuarioFormulario
  ): Promise<IRespostaFuncao<IUsuarioMongoDB>> => {
    const usuarioMongoDB: IUsuarioMongoDB = converteUsuarioFormularioEmUsuarioMongoDB(usuarioFormulario);
    return usuarioMongoAppServices.functions['empresas/usuarios/criaUsuario'](usuarioMongoDB)
      .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao criar usuário na base de dados',
          causa: e
        };
        throw new FarmaError(erro);
    });
};

// ------------------------ ATUALIZAR USUÁRIO ------------------------ //
const atualiza = async (
    usuarioMongoAppServices: RealmUser,
    usuarioFormulario: IUsuarioFormulario
  ): Promise<IRespostaFuncao<IUsuarioMongoDB>> => {
    const usuarioMongoDB: IUsuarioMongoDB = converteUsuarioFormularioEmUsuarioMongoDB(usuarioFormulario);
    const dadosUsuario: IUsuarioMongoDB = {
      firebaseId: usuarioMongoDB.firebaseId,
      name: usuarioMongoDB.name,
      isAdmin: usuarioMongoDB.isAdmin,
      privilegeHash: usuarioMongoDB.privilegeHash,
      loja: usuarioMongoDB.loja,
      job: usuarioMongoDB.job,
    };
    return usuarioMongoAppServices.functions['empresas/usuarios/atualizaUsuario'](dadosUsuario)
      .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao atualizar usuário na base de dados',
          causa: e
        };
        throw new FarmaError(erro);
    });
};

// ------------------------ DELETA USUÁRIO ------------------------ //
const deleta = async (
    usuarioMongoAppServices: RealmUser,
    usuarioFormulario: IUsuarioFormulario
  ): Promise<IRespostaFuncao<IUsuarioMongoDB>> => {
    const usuarioMongoDB: IUsuarioMongoDB = converteUsuarioFormularioEmUsuarioMongoDB(usuarioFormulario);
    return usuarioMongoAppServices.functions['empresas/usuarios/removeUsuario']({ firebaseId: usuarioMongoDB.firebaseId })
      .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao remover usuário na base de dados',
          causa: e
        };
        throw new FarmaError(erro);
    });
};

// ------------------------ ATRIBUIR EMPRESAS AO USUÁRIO ------------------------ //
const atribuiEmpresas = async (
  usuarioMongoAppServices: RealmUser,
  usuarioFormulario: IUsuarioFormulario
): Promise<IRespostaFuncao<IUsuarioMongoDB>> => {
  const usuario: IUsuarioMongoDB = {
    firebaseId: usuarioFormulario.id,
    emps: usuarioFormulario.emps
  };
  return usuarioMongoAppServices.functions['usuarios/atribuiEmpresas'](usuario)
    .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atribuir Empresas ao Usuário',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ OBTEM PAPEL USUARIO ------------------------ //
const obtemPapelUsuario = async (
  usuarioMongoAppServices: RealmUser,
): Promise<IRespostaFuncao<IPapel>> => {
  return usuarioMongoAppServices.functions['usuarios/papeis/obtemPapel']()
    .then((resultado) => resultado as IRespostaFuncao<IPapel>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter Papel do Usuário Atual',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ ATRIBUIR PAINÉIS A USUÁRIOS ------------------------ //
const atribuiPaineis = async (
  usuarioMongoAppServices: RealmUser,
  userId: IUsuarioMongoDB,
  painelUsuario: IpainelUsuario[]
): Promise<IRespostaFuncao<IpainelUsuario[]>> => {
  return usuarioMongoAppServices.functions['usuarios/atribuiPaineis']({
    firebaseId: userId,
    paineis: painelUsuario
  })
    .then((resultado) => resultado as IRespostaFuncao<IpainelUsuario[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atribuir painéis na base de dados',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ REMOVER PAINÉIS ------------------------ //
const removePaineis = async (
  usuarioMongoAppServices: RealmUser,
  userId: string
): Promise<IRespostaFuncao<IpainelUsuario[]>> => {
  return usuarioMongoAppServices.functions['usuarios/removePaineisAtribuidos']({
    firebaseId: userId
  })
    .then((resultado) => resultado as IRespostaFuncao<IpainelUsuario[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao remover painéis atribuidos',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

export const Usuarios = {
  obtem,
  cria,
  atualiza,
  deleta,
  obtemPapelUsuario,
  atribuiEmpresas,
  atribuiPaineis,
  removePaineis
};
