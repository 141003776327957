import {
  AUTH_STATE_CHANGED,
  USER_DATA_INIT,
  CLEAR_USER_AUTH,
  GET_EMPRESA_INIT,
  GET_EMPRESA_SUCCESS,
  SET_EMPRESA,
  AUTH_ERROR_MSG,
  GET_PAINEIS_EMPRESA_INIT,
  GET_PAINEIS_EMPRESA_SUCCESS,
  CLEAR_LIST_PAINEIS_EMP
} from '../actionTypes';

export const authStateChanged = (data) => ({
  type: AUTH_STATE_CHANGED,
  payload: data
});

export const userDataRequestInit = (data) => ({
  type: USER_DATA_INIT,
  payload: data
});

export const clearUserAuth = (data) => ({
  type: CLEAR_USER_AUTH,
  payload: data
});

export const getEmpresaInit = ({ usuarioMongoAppServices, empId }) => ({
  type: GET_EMPRESA_INIT,
  payload: { usuarioMongoAppServices, empId }
});

export const getEmpresaSuccess = ({ myEmp }) => ({
  type: GET_EMPRESA_SUCCESS,
  payload: { myEmp }
});

export const setEmpresa = ({ empresa }) => ({
  type: SET_EMPRESA,
  payload: { empresa }
});

export const authErrorMsg = (data) => ({
  type: AUTH_ERROR_MSG,
  payload: data
});

export const getPaineisEmpresaAtualInit = (usuarioMongoAppServices, myEmp) => ({
  type: GET_PAINEIS_EMPRESA_INIT,
  payload: { usuarioMongoAppServices, myEmp }
});

export const getPaineisEmpresaAtualSuccess = (data) => {
  return {
    type: GET_PAINEIS_EMPRESA_SUCCESS,
    payload: data
  };
};

export const clearPaineisEmpAtual = (data) => ({
  type: CLEAR_LIST_PAINEIS_EMP,
  payload: data
});
