import { User as RealmUser } from 'realm-web';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { ICategoria } from '../modelos/ICategoria';
import { FarmaError } from 'src/integracoes/modelos/erros';

// ------------------------ OBTEM LISTA CATEGORIAS ------------------------ //
const obtemCategorias = async (
  usuarioMongoAppServices: RealmUser
): Promise<IRespostaFuncao<ICategoria[]>> => {
  return usuarioMongoAppServices.functions['categorias/obtemCategorias']()
    .then((resultado) => resultado as IRespostaFuncao<ICategoria[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter lista de Categorias',
        causa: e
      };
      throw new FarmaError(erro);
    });
};
  
// ------------------------ CRIAR CATEGORIA ------------------------ //
const cria = async (
    usuarioMongoAppServices: RealmUser,
    categoria: ICategoria
  ): Promise<IRespostaFuncao<ICategoria>> => {
    return usuarioMongoAppServices.functions['categorias/criaCategoria'](categoria)
      .then((resultado) => resultado as IRespostaFuncao<ICategoria>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao criar Categoria',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
// ------------------------ ATUALIZAR CATEGORIA ------------------------ //
const atualiza = async (
    usuarioMongoAppServices: RealmUser,
    categoria: ICategoria
  ): Promise<IRespostaFuncao<ICategoria>> => {
    return usuarioMongoAppServices.functions['categorias/atualizaCategoria'](categoria)
      .then((resultado) => resultado as IRespostaFuncao<ICategoria>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao atualizar Categoria',
          causa: e
        };
        throw new FarmaError(erro);
    });
};

// ------------------------ DELETAR CATEGORIA ------------------------ //
const deleta = async (
    usuarioMongoAppServices: RealmUser,
    categoria: ICategoria
  ): Promise<IRespostaFuncao<ICategoria>> => {
    return usuarioMongoAppServices.functions['categorias/removeCategoria'](categoria)
      .then((resultado) => resultado as IRespostaFuncao<ICategoria>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao remover Categoria',
          causa: e
        };
        throw new FarmaError(erro);
    });
};  

export const Categorias = {
    obtemCategorias,
    cria,
    atualiza,
    deleta
}