import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SET_ESTOQUE_DADOS_INIT } from '../actionTypes';
import { setExcessoEstoqueSuccess, errorEstoqueMsg } from './actions';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { IEmpresaGrupo } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';
import { User as RealmUser } from 'realm-web';

// Atualizar quantidade excesso estoque comunicacao com o banco -------------------------
const setExcessoEstoqueAsync = async ({ usuarioMongoAppServices, empId, grupos }:
  { usuarioMongoAppServices: RealmUser, empId: string, grupos: IEmpresaGrupo[] }) => {
  const { status } = await Empresas.atualizaExcessoEstoque(usuarioMongoAppServices, empId, grupos);
  if (status) {
    return { isUpdated: true, msg: 'Dados atualizados com sucesso!' };
  } else {
    return { isUpdated: false, msg: 'Erro ao atualizar dados' };
  }
};

// Atualizar quantidade excesso estoque funcao chamada pelo dispatch ---------------------
function* setExcessoEstoqueMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empId, grupos } = payload;
  try {
    const { isUpdated, msg } = yield call(setExcessoEstoqueAsync, {
      usuarioMongoAppServices,
      empId,
      grupos
    });
    if (isUpdated) {
      yield put(setExcessoEstoqueSuccess({ msg }));
    } else {
      yield put(errorEstoqueMsg({ msg }));
    }
  } catch (error: any) {
    yield put(errorEstoqueMsg({ msg: error.mensagem }));
  }
}

export function* handleUpdateExcessoEstoque() {
  yield takeEvery(SET_ESTOQUE_DADOS_INIT, setExcessoEstoqueMiddle);
}

// WATCHS ------------------------------------------------
export default function* rootSaga() {
  yield all([fork(handleUpdateExcessoEstoque)]);
}
