import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Logo from '../../components/Logo';
import NavItem from './NavItem';
import { PAPEL1, PAPEL2, PAPEL3, PAPEL4, PAPELDEFAULT } from '../../utils/rbac/papeis';
import HasAccessItemsMenu from '../../utils/rbac/componentsByRole';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { listMenuUrl } from '../../utils/functions';
import { isNil } from 'lodash';
import { sectionsMenu } from 'src/constants';
import { getPaineisEmpresaAtualInit, setEmpresa } from 'src/redux/actions';
import AdminEmpSelection from 'src/components/AdminEmpSelection';
import { useSnackbar } from 'notistack';
import { versaoFrontend } from 'src/config';

function renderNavItems({ items, pathname, depth = 0, myPrivilege }) {
  const filteredItems = HasAccessItemsMenu({ items, myPrivilege });
  return (
    <List disablePadding>
      {filteredItems.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, myPrivilege }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, myPrivilege }) {
  const { title, href, icon, info, items, entidadeNome } = item;
  const key = `${title}-${depth}-${href || ''}`;

  const isRelatorio = title === 'Relatórios';

  const open = isRelatorio || matchPath(pathname, {
    path: href,
    exact: false
  });

  const navItemProps = {
    depth,
    icon,
    info,
    key,
    open: Boolean(open),
    title,
    svgIcon: Boolean(false),
    entidadeNome
  };

  const renderedNavItem = items
    ? (
      <NavItem {...navItemProps}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items,
          myPrivilege
        })}
      </NavItem>
    )
    : (
      <NavItem
        depth={depth}
        href={href}
        icon={item.icon}
        info={info}
        key={key}
        title={title}
        svgIcon={item.svgIcon}
        entidadeNome={entidadeNome}
      />
    );

  acc.push(renderedNavItem);
  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 55,
    height: 'calc(100% - 55px)',
    borderRight: '2px solid rgba(0, 0, 0, 0.0)',
    '&::-paperAnchorDockedLeft': {
      borderRight: '5px solid #ffffff'
    },
    '&::-webkit-scrollbar': {
      height: '6px',
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: '#DFE9EB',
      border: '10px solid #FFFFFF',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: '#B8C0C2',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgba(154,154,154, 0.5)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(154,154,154, 0.7)',
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: 'rgba(154,154,154, 0.9)',
    },
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  iconOpenClose: {
    marginTop: 12,
    float: 'right',
    color: theme.palette.text.secondary
  },
  button: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
    letterSpacing: 0,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%'
  },
  rodape: {
    paddingTop: theme.spacing(2)
  },
  subHeader: {
    float: 'left'
  },
  subHeaderFont: {
    color: theme.palette.text.secondary
  },
  active: {
    color: `${theme.palette.primary.main}!important`
  },
  containerNav: {
    display: 'flex',
    flexDirection: 'column'
  },
  topoNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px 10px'
  },
  footerNav: {
    flex: 0,
    flexDirection: 'column-reverse',
    paddingTop: 12
  },
  lista: {
    padding: '0 8px'
  },
  subheader: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    flex: 1,
    height: '1px',
    backgroundColor: theme.palette.divider,
    marginLeft: '16px',
    position: 'relative',
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    myEmp,
    usuarioMongoAppServices,
    myPrivilege,
    paineisUsuarioAtual,
    categorias,
    paineisEmpresaAtual,
    isError,
    msg
  } = useSelector(
    (state) => ({
      myEmp: state.account.myEmp,
      usuarioMongoAppServices: state.account.user.usuarioMongoAppServices,
      myPrivilege: String(state.account.user?.privilegeHash || PAPELDEFAULT),
      paineisUsuarioAtual: state.account.user.paineis,
      categorias: state.categorias.categorias,
      paineisEmpresaAtual: state.account.paineisEmpresaAtual,
      isError: state.account.error,
      msg: state.account.msg
    }),
    shallowEqual
  );

  const userPermission = myPrivilege; // Defina as permissões do usuário
  const [sections, setSection] = useState([]);
  const [meusPaineis, setMeusPaineis] = useState([]);

  // Efeito para carregar os dados dos painéis da empresa ativa na plataforma
  useEffect(() => {
    // carrega apenas os paineis do tipo empresa
    // e se a empresa possuir o atributo pbiRelatorio
    if (myPrivilege !== PAPEL1) {
      dispatch(getPaineisEmpresaAtualInit(usuarioMongoAppServices, myEmp.id));
    }
  }, [myEmp.id, usuarioMongoAppServices]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(msg, {
        variant: 'info'
      });
    }
  }, [isError]);

  useEffect(() => {
    const paineisUser = myPrivilege === PAPEL1 ? paineisUsuarioAtual : paineisEmpresaAtual;
    if (isNil(paineisUser) || paineisUser.length === 0) {
      if (myPrivilege === PAPEL1) {
        enqueueSnackbar('Usuário não possui painéis atribuídos', {
          variant: 'info'
        });
      }
      setMeusPaineis([]);
    } else {
      setMeusPaineis(paineisUser);
    }
  }, [paineisUsuarioAtual, paineisEmpresaAtual]);

  const handleEmp = (emp) => {
    dispatch(setEmpresa({ empresa: emp }));
    return true;
  };

  const hasSectionPermission = (sectionPermissions, userPermissions) => {
    if (!sectionPermissions) {
      // Se a seção não especifica permissões, ela é acessível para todos
      return true;
    }
    // Verifique se o usuário possui pelo menos uma das permissões da seção
    return sectionPermissions.some((permission) => userPermissions.includes(permission));
  };

  const filteredSections = sectionsMenu.filter((section) => {
    return hasSectionPermission(section.allowedRoles, userPermission);
  });

  // Função para verificar se o item "Relatorios" deve ser ocultado
  const ocultarItemRelatorio = (paineis) => {
    const paineisDoUsuario = paineis.length === 0;
    return paineisDoUsuario;
  }

  // Oculta o item "Relatorios" se necessário
  if (ocultarItemRelatorio(meusPaineis)) {
    filteredSections.shift();
  }

  useEffect(() => {
    const sectionsloader = () => {
      if (isMountedRef.current && !isNil(categorias) && !isNil(meusPaineis) && meusPaineis.length > 0) {
        const fullPaineis = [];
        // Percorre cada categoria
        categorias.forEach(categ => {
          // Filtra os painéis que correspondem à categoria atual
          const categoriaPaineis = meusPaineis
            .filter(painel => categ.id === painel.categoriaId)
            // Mapeia os painéis filtrados para um novo formato esperado
            .map(painel => ({
              painelId: painel.painelId,
              name: painel.nome,
              entidadeNome: painel.entidadeNome,
              entidadeTipo: painel.entidadeTipo,
              icon: painel.icone,
              lojaId: painel.entidadeTipo === 'loja' ? painel.entidadeId : undefined
            }));
          // Verifica se há painéis para a categoria atual
          if (categoriaPaineis.length > 0) {
            fullPaineis.push({
              categoria: categ.name, // como os itens serão agrupados
              categoriaId: categ.id,
              paineis: categoriaPaineis
            });
          }
        });
        // Chama a função para criar a estrutura do menu
        const lista = listMenuUrl({
          baseUrl: '',
          itens: fullPaineis,
          empId: myEmp.id,
          subHeaderName: 'categoria',
          subHeaderIdName: 'categoriaId',
          itemName: 'paineis',
          subItemIdName: 'painelId',
          subItemLojaIdName: 'lojaId',
          papeis: [PAPEL1, PAPEL2, PAPEL3, PAPEL4]
        });

        // Atualiza o sections com a lista gerada
        setSection(lista);
      }
    };

    sectionsloader();
  }, [categorias, isMountedRef, meusPaineis]);

  // Atualiza o items do primeiro elemento do sectionsMenu com os dashboards
  sectionsMenu[0].items = sections;


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <AdminEmpSelection handleEmp={handleEmp} />
        <Box>
          {filteredSections.map((section, index) => (
            <React.Fragment key={section.subheader || index}>
              <List
                disablePadding
                className={classes.lista}
                subheader={
                  <ListSubheader disableGutters disableSticky className={classes.subheader}>
                    {section.subheader}
                    {/* Condicional para adicionar a linha somente se section.subheader existir */}
                    {section.subheader && <div className={classes.line}></div>}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  myPrivilege: myPrivilege
                })}
              </List>
              {/* {section.subheader === 'Relatórios' && myPrivilege === PAPEL2 && (
                <Divider />
              )} */}
            </React.Fragment>
          ))
          }
        </Box>
        <Divider />
        <Box className={classes.footerNav} display="flex">
          <Typography
            align="center"
            display="inline"
            variant="body2"
            color="textSecondary"
          >
            Versão {versaoFrontend}-{userPermission}
          </Typography>
        </Box>
      </>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;