import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from '../../components/Logo';
import { Theme } from '../../theme';
import Account from './Account';

interface TopBarProps {
  onMobileNavOpen: (data: any) => any;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    minHeight: 64
  },
  empName: {
    paddingLeft: theme.spacing(2)
  }
}));

const TopBar: FC<TopBarProps> = ({
  onMobileNavOpen
}: TopBarProps) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root)}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="primary" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Account />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
