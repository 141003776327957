import { FarmaError } from 'src/integracoes/modelos/erros';
import { IParametrosFuncao, IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import {
  FunctionsError,
  HttpsCallableResult,
  getFunctions,
  httpsCallable
} from 'firebase/functions';
import {
  IUsuarioFirebase,
  IUsuarioFormulario,
  IUsuarioMongoDB
} from 'src/integracoes/modelos/usuarios';
import { app } from './app';
import { montaUsuarioFirebase } from './utils';
import { IRequisicaoPowerBiEmbedded } from '../powerbi/modelos/IRequisicaoPowerBiEmbedded';
import { IEmbedConfig } from '../powerbi/modelos/IEmbedConfig';

const functions = getFunctions(app, 'southamerica-east1');

const obtemUsuariosFirebase = async (
  empId: string
): Promise<IRespostaFuncao<IUsuarioFirebase[] | FarmaError>> => {
  const funcao = httpsCallable(functions, 'usuarios-obtemUsuarios-v1');
  const resposta = funcao({ empresa: empId })
    .then((result: HttpsCallableResult) => {
      const resp: IRespostaFuncao<any> = result.data as IRespostaFuncao<any>;
      if (!resp.status) {
        throw new FarmaError(resp.dados);
      }
      return resp;
    })
    .catch((erro: any) => {
      throw new FarmaError(erro);
    });
  return resposta;
};

const criaUsuarioFirebase = async (
  usuarioFirebase: IUsuarioFirebase
): Promise<IRespostaFuncao<IUsuarioFirebase | FarmaError>> => {
  const funcao = httpsCallable(functions, 'usuarios-criaUsuario-v1');
  const resposta = funcao(usuarioFirebase)
    .then((result: HttpsCallableResult) => {
      const resp: IRespostaFuncao<any> = result.data as IRespostaFuncao<any>;
      if (!resp.status) {
        throw new FarmaError(resp.dados);
      }
      return resp;
    })
    .catch((erro: any) => {
      throw new FarmaError(erro);
    });
  return resposta;
};

const atualizaUsuarioFirebase = async (
  usuarioFormulario: IUsuarioFormulario
): Promise<IRespostaFuncao<IUsuarioFirebase | FarmaError>> => {
  const funcao = httpsCallable(
    functions,
    'usuarios-atualizaUsuario-v1'
  );
  const usuarioFirebase: IUsuarioFirebase = montaUsuarioFirebase(
    usuarioFormulario
  );
  const resposta = funcao(usuarioFirebase)
    .then((result: HttpsCallableResult) => {
      const resp: IRespostaFuncao<any> = result.data as IRespostaFuncao<any>;
      if (!resp.status) {
        throw new FarmaError(resp.dados);
      }
      return resp;
    })
    .catch((erro: any) => {
      throw new FarmaError(erro);
    });
  return resposta;
};

const removeUsuarioFirebase = async (
  usuarioFormulario: IUsuarioFormulario
): Promise<IRespostaFuncao<IUsuarioFirebase | FarmaError>> => {
  const funcao = httpsCallable(functions, 'usuarios-removeUsuario-v1');
  const { uid } = montaUsuarioFirebase(usuarioFormulario);
  const resposta = funcao({ uid })
    .then((result: HttpsCallableResult) => {
      const resp: IRespostaFuncao<any> = result.data as IRespostaFuncao<any>;
      if (!resp.status) {
        throw new FarmaError(resp.dados);
      }
      return resp;
    })
    .catch((erro: any) => {
      throw new FarmaError(erro);
    });
  return resposta;
};

const enviaEmailAtivacao = async (
  usuarioFormulario: IUsuarioFormulario
): Promise<IRespostaFuncao<IUsuarioMongoDB | FarmaError>> => {
  const funcao = httpsCallable(
    functions,
    'transacional-enviaEmailAtivacao-v1'
  );
  const { email } = montaUsuarioFirebase(usuarioFormulario);
  const resposta = funcao({ name: email, email })
    .then((result: HttpsCallableResult) => {
      const resp: IRespostaFuncao<any> = result.data as IRespostaFuncao<any>;
      if (!resp.status) {
        throw new FarmaError(resp.dados);
      }
      return resp;
    })
    .catch((erro: any) => {
      throw new FarmaError(erro);
    });
  return resposta;
};

const relatoriosObtemEmbedConfig = async (
  parametros: IParametrosFuncao<IRequisicaoPowerBiEmbedded>
): Promise<IRespostaFuncao<IEmbedConfig>> => {
  const funcao = httpsCallable(functions, 'relatorios-obtemEmbedConfig-v1');
  const resposta = funcao(parametros)
    .then((result: HttpsCallableResult) => {
      return result.data as IRespostaFuncao<IEmbedConfig>;
    })
    .catch((e: FunctionsError) => {
      const erro = {
        nome: 'FIREBASE-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter dados no serviço de relatórios',
        causa: e
      };
      throw new FarmaError(erro);
    });
  return resposta;
};

export {
  obtemUsuariosFirebase,
  criaUsuarioFirebase,
  atualizaUsuarioFirebase,
  removeUsuarioFirebase,
  enviaEmailAtivacao,
};

export const firebaseFunctions = { relatoriosObtemEmbedConfig };
