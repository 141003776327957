import { User as RealmUser } from 'realm-web';
import { IColaborador } from '../modelos/IColaborador';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { FarmaError } from 'src/integracoes/modelos/erros';

// ------------------------ CRIAR COLABORADOR  ------------------------ //
const cria = async (
    usuarioMongoAppServices: RealmUser,
    colaborador: IColaborador
  ): Promise<IRespostaFuncao<IColaborador>> => {
    return usuarioMongoAppServices.functions['empresas/lojas/colaboradores/criaColaborador'](colaborador)
      .then((resultado) => resultado as IRespostaFuncao<IColaborador>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao criar Colaborador',
          causa: e
        };
        throw new FarmaError(erro);
      });
  };
  
// ------------------------ ATUALIZA COLABORADOR  ------------------------ //
const atualiza = async (
    usuarioMongoAppServices: RealmUser,
    colaborador: IColaborador
  ): Promise<IRespostaFuncao<IColaborador>> => {
    return usuarioMongoAppServices.functions['empresas/lojas/colaboradores/atualizaColaborador'](colaborador)
      .then((resultado) => resultado as IRespostaFuncao<IColaborador>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao editar Colaborador',
          causa: e
        };
        throw new FarmaError(erro);
    });
};

export const Colaboradores = {
    cria,
    atualiza,
}