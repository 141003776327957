// função que faz o parse do IUsuarioFormulário
// para IUsuárioMongoDB

import {
  IUsuarioFirebase,
  IUsuarioFormulario,
} from 'src/integracoes/modelos/usuarios';

// recebe um IUsuarioFormulário
// retorna um IUsuárioFirebase
export function montaUsuarioFirebase(
  usuarioFormulario: IUsuarioFormulario
): IUsuarioFirebase {
  const usuarioFirebase: IUsuarioFirebase = {
    uid: usuarioFormulario.id,
    email: usuarioFormulario.email,
    // desativado: !usuarioFormulario.isActive, // validar essa parte
  };
  return usuarioFirebase;
}


