import {
  SET_PAINEIS_USER_INIT,
  SET_PAINEIS_USER_SUCCESS,
  PAINEL_USER_ERROR_MSG,
  PAINEL_USER_CLEAN_UP
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  isUpdated: false,
  isError: false,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAINEIS_USER_INIT:
      return { ...state, isUpdated: false, loading: true };
    case SET_PAINEIS_USER_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        msg,
        loading: false,
        isUpdated: true
      };
    }
    case PAINEL_USER_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, msg, isError: true };
    }
    case PAINEL_USER_CLEAN_UP: {
      return {
        ...state,
        isUpdated: false,
        isError: false,
        loading: false,
        msg: ''
      };
    }
    default:
      return { ...state };
  }
};
