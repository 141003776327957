import { User as RealmUser } from 'realm-web';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { IpainelFormulario } from 'src/integracoes/modelos/painel';

// ------------------------ LISTAR PAINÉIS ------------------------ //
const obtem = async (
  usuarioMongoAppServices: RealmUser
): Promise<IRespostaFuncao<IpainelFormulario[]>> => {
  ['paineis/obtemPaineis']
  return usuarioMongoAppServices.functions['paineis/obtemPaineis']()
    .then((resultado) => resultado as IRespostaFuncao<IpainelFormulario[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao buscar painéis na base de dados',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ CRIAR PAINEL ------------------------ //
const cria = async (
  usuarioMongoAppServices: RealmUser,
  painelFormulario: IpainelFormulario
): Promise<IRespostaFuncao<any>> => {
  ['paineis/criaPainel']
  return usuarioMongoAppServices.functions['paineis/criaPainel'](painelFormulario)
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao criar painel na base de dados',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ ATUALIZAR PAINEL ------------------------ //
const atualiza = async (
  usuarioMongoAppServices: RealmUser,
  painelFormulario: IpainelFormulario
): Promise<IRespostaFuncao<any>> => {
  return usuarioMongoAppServices.functions['paineis/atualizaPainel'](painelFormulario)
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atualizar painel na base de dados',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ DELETAR PAINEL ------------------------ //
const deleta = async (
  usuarioMongoAppServices: RealmUser,
  painelFormulario: IpainelFormulario
): Promise<IRespostaFuncao<any>> => {
  ['paineis/removePainel']
  return usuarioMongoAppServices.functions['paineis/removePainel'](painelFormulario)
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao remover painel na base de dados',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

export const Paineis = {
  obtem,
  cria,
  atualiza,
  deleta
};





