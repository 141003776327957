import * as Realm from 'realm-web';
import { FarmaError } from 'src/integracoes/modelos/erros';
import app from './app';

export async function loginMongoAppServices(jwt: string): Promise<Realm.User> {
  const credentials = Realm.Credentials.jwt(jwt);
  let usuarioMongoAppServices: Realm.User;
  
  try {
    usuarioMongoAppServices = await app.logIn(credentials);
  } catch(e) {
    const erro = {
      nome: 'MONGO-APPSERVICES-FALHA-LOGIN',
      mensagem: 'Falha ao obter as credenciais de acesso ao serviço de dados',
      causa: e
    };
    throw new FarmaError(erro);
  } 
  
  if (usuarioMongoAppServices.id !== app.currentUser?.id) {
    const erro = {
      nome: 'MONGO-APPSERVICES-FALHA-LOGIN',
      mensagem: 'Falha ao obter as credenciais de acesso ao serviço de dados',
      causa: new Error('A credencial obtida é diferente da credencial do usuário atual')
    };
    throw new FarmaError(erro);
  }
  
  return usuarioMongoAppServices;
}

export async function logoutMongoAppServices(): Promise<void> {
  return app.currentUser?.logOut();
}

export function hasLoggedInUserRealm(): unknown {
  // Check if there is currently a logged in user
  return app.currentUser?.isLoggedIn;
}

export function getCurrentUserRealm(): unknown {
  // Return the user object of the currently logged in user
  return app.currentUser?.isLoggedIn ? app.currentUser?.profile : null;
}

export async function obtemDadosSecundariosDoUsuarioMongoAppServices(): Promise<Record<string, unknown>> {
  return app.currentUser?.isLoggedIn ? app.currentUser?.customData : {};
}
