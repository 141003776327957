import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CREATE_CAMPANHA_INIT,
  UPDATE_CAMPANHA_INIT,
  CAMPANHA_LIST_CAMPANHA_INIT,
  CAMPANHA_OBTEM_CAMPANHA_INIT,
  CAMPANHA_DELETE_CAMPANHA_INIT,
  DUPLICATE_CAMPANHA_INIT
} from '../actionTypes';
import {
  createCampanhaSucess,
  updateCampanhaSuccess,
  campanhaErrorMsg,
  obtemCampanhasListSuccess,
  obtemCampanhaSuccess,
  campanhaDeleteCampanhaSuccess,
  duplicateCampanhaSuccess
} from './actions';

import { FarmaError } from 'src/integracoes/modelos/erros';
import { ICampanha } from 'src/integracoes/servicos/mongo-atlas/modelos/ICampanha';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { Campanhas } from 'src/integracoes/servicos/mongo-atlas/funcoes/campanhas';
import { User as RealmUser } from 'realm-web';

interface FetchListaCampanhasResult {
  isError: boolean;
  campanhas: ICampanha[] | null;
  msg: string | null;
}

interface FetchCampanhaResult {
  isError: boolean;
  campanha: ICampanha | null;
  errorMessage: string | null;
}

// --------------------Listar Campanhas por empresa -----------------------------------------

const fetchListarCampanhas = async ({ empId, usuarioMongoAppServices }: { empId: string, usuarioMongoAppServices: RealmUser }): Promise<FetchListaCampanhasResult> => {
  try {
    const { status, dados, mensagem } = await Empresas.obtemCampanhas(usuarioMongoAppServices, empId);
    if (Array.isArray(dados)) {
      return {
        isError: !status,
        campanhas: dados,
        msg: mensagem
      };
    }
    return {
      isError: true,
      campanhas: [],
      msg: mensagem
    };
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseFetchListaCampanhas({ payload }: { payload: any, type: any }) {
  const { empId, usuarioMongoAppServices } = payload;
  try {
    const campanhaData: FetchListaCampanhasResult = yield call(fetchListarCampanhas, { empId, usuarioMongoAppServices });
    if (!campanhaData.isError) {
      yield put(obtemCampanhasListSuccess(campanhaData));
    } else {
      yield put(campanhaErrorMsg(campanhaData.msg));
    }
  } catch (error: any) {
    yield put(campanhaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchFetchListaCampanhas() {
  yield takeEvery(CAMPANHA_LIST_CAMPANHA_INIT, parseFetchListaCampanhas);
}

// -------------------- Obter Campanha -----------------------------------------

const obterCampanha = async ({ usuarioMongoAppServices, id }: { usuarioMongoAppServices: RealmUser, id: string }) => {
  try {
    const { status, dados } = await Campanhas.obtem(usuarioMongoAppServices, id);
    if (status) {
      return {
        isError: !status,
        campanha: dados as ICampanha,
        errorMessage: null
      };
    }
    return {
      isError: !status,
      campanha: null,
      errorMessage: 'Falha ao carregar dados'
    };

  } catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseFetchCampanha({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, id } = payload;
  try {
    const campanhaData: FetchCampanhaResult = yield call(obterCampanha, { usuarioMongoAppServices, id });
    if (!campanhaData.isError) {
      yield put(obtemCampanhaSuccess(campanhaData));
    } else {
      yield put(campanhaErrorMsg({ msg: campanhaData.errorMessage }));
    }
  } catch (error: any) {
    yield put(campanhaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchFetchCampanha() {
  yield takeEvery(CAMPANHA_OBTEM_CAMPANHA_INIT, parseFetchCampanha);
}

// ---------------------------Criar Campanha---------------------------------------------------------

const createCampanhaAsync = async ({
  usuarioMongoAppServices,
  campanha
}: {
  usuarioMongoAppServices: RealmUser,
  campanha: ICampanha
}) => {
  try {
    const { status, dados, mensagem } = await Campanhas.cria(
      usuarioMongoAppServices, campanha
    );
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* createCampanhaMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, campanhaData } = payload;
  try {
    const { isCreated, msg, data } = yield call(createCampanhaAsync, {
      usuarioMongoAppServices,
      campanha: campanhaData      
    });
    if (isCreated) {
      yield put(createCampanhaSucess({ current: data, msg }));
    } else {
      yield put(campanhaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(campanhaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchCreateCampanha() {
  yield takeEvery(CREATE_CAMPANHA_INIT, createCampanhaMiddle);
}

// ------------------------- Atualizar Campanha-------------------------------------------

const updateCampanhaAsync = async ({ usuarioMongoAppServices,campanha }: {
  usuarioMongoAppServices: RealmUser,
  campanha: ICampanha
}) => {
  try {
    const resposta = await Campanhas.atualiza(
      usuarioMongoAppServices,
      campanha
    );
    if (resposta.status) {
      return { isUpdated: true, msg: resposta.mensagem };
    } else {
      return {
        isUpdated: false,
        msg: resposta.mensagem
      };
    }
  }
  catch (error: any) {
    throw new FarmaError(error);
  }
};

function* updateCampanhaMiddle({ payload }: { payload: any, type: any }) {
  const {
    usuarioMongoAppServices,
    campanha
  } = payload;
  try {
    const { isUpdated, msg } = yield call(updateCampanhaAsync, {
      usuarioMongoAppServices,
      campanha
    });
    if (isUpdated) {
      yield put(updateCampanhaSuccess({ msg }));
    } else {
      yield put(campanhaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(campanhaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchUpdateCampanha() {
  yield takeEvery(UPDATE_CAMPANHA_INIT, updateCampanhaMiddle);
}

// -------------------------- DELETAR CAMPANHA --------------------

export const removeCampanha = async ({ usuarioMongoAppServices, id }: { usuarioMongoAppServices: RealmUser, id: string }) => {
  try {
    const { status, mensagem } = await Campanhas.deleta(usuarioMongoAppServices, id);
    if (status) {
      return { isUpdated: true, msg: mensagem };
    } else {
      return {
        isUpdated: false,
        msg: mensagem
      };
    }
  }
  catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseRemoveCampanha({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, id } = payload;
  try {
    const { isUpdated, msg } = yield call(removeCampanha, {
      usuarioMongoAppServices,
      id
    });
    if (isUpdated) {
      yield put(campanhaDeleteCampanhaSuccess({ msg }));
    } else {
      yield put(campanhaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(campanhaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchRemoveCampanha() {
  yield takeEvery(CAMPANHA_DELETE_CAMPANHA_INIT, parseRemoveCampanha);
}

// ------------------------- DUPLICAR CAMPANHA -------------------------------------------

const duplicateCampanhaAsync = async ({ usuarioMongoAppServices, id }: { usuarioMongoAppServices: RealmUser, id: string }) => {
  try {
    const { status, mensagem } = await Campanhas.duplica(usuarioMongoAppServices, id);
    if (status) {
      return { isUpdated: true, msg: mensagem };
    } else {
      return {
        isUpdated: false,
        msg: mensagem
      };
    }
  }
  catch (error: any) {
    throw new FarmaError(error);
  }
};

function* duplicateCampanhaMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, id } = payload;
  try {
    const { isUpdated, msg } = yield call(duplicateCampanhaAsync, { usuarioMongoAppServices, id });
    if (isUpdated) {
      yield put(duplicateCampanhaSuccess({ msg }));
    } else {
      yield put(campanhaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(campanhaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchDuplicateCampanha() {
  yield takeEvery(DUPLICATE_CAMPANHA_INIT, duplicateCampanhaMiddle);
}


// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchCreateCampanha),
    fork(watchFetchListaCampanhas),
    fork(watchFetchCampanha),
    fork(watchUpdateCampanha),
    fork(watchRemoveCampanha),
    fork(watchDuplicateCampanha)
  ])
}
