import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  SEND_LOG
} from '../actionTypes';
import { LogErroMsg } from '../actions';
import { Logs } from 'src/integracoes/servicos/mongo-atlas/funcoes/logs';
import { IMetricaVisualizacaoDashboard } from 'src/integracoes/servicos/mongo-atlas/modelos/IMetricaVisualizacaoDashboard';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { User as RealmUser } from 'realm-web';

// ---------------- Envia logs para mongo -------------------------------

interface FetchLogResult {
  flag: boolean;
  log: IMetricaVisualizacaoDashboard;
  message: string;
}

const sendLogAsync = async ({ usuarioMongoAppServices, logData }: {usuarioMongoAppServices: RealmUser, logData: IMetricaVisualizacaoDashboard}): Promise<FetchLogResult> => {
  try {
    const { status, mensagem, dados } = await Logs.enviaLog(usuarioMongoAppServices, logData);
    if (status) {
      return { flag: true, log: dados as IMetricaVisualizacaoDashboard, message: '' };
    } else {
      return { flag: false, log: dados as IMetricaVisualizacaoDashboard, message: mensagem };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
}

function* sendLogData({ payload }: {payload: any, type: any}) {
  const { userId, timestamp, dashId, empId, usuarioMongoAppServices } = payload;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const logData: IMetricaVisualizacaoDashboard = {
    empId: empId,
    userId: userId,
    timestamp: timestamp,
    date: today.toISOString(),
    dashId: dashId
  };
  try {
    const log: FetchLogResult = yield call(sendLogAsync, {
      usuarioMongoAppServices,
      logData
    });
  } catch (error: any) {
    yield put(LogErroMsg({msg: error.mensagem}));
  }
}
export function* watchSendLog() {
  yield takeEvery(SEND_LOG, sendLogData);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchSendLog)
  ]);
}
