/* eslint-disable prefer-template */
import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { FC, ReactNode } from 'react';
import type { User } from '../@types/user';
import SplashScreen from '../components/SplashScreen';
import firebase from '../lib/firebase';
import {
  authStateChanged,
  userDataRequestInit,
  clearUserAuth,
  empresaCleanListEmps
} from '../redux/actions';
import { RootState } from '../redux/types';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { FarmaError } from 'src/integracoes/modelos/erros';
import {
  obtemDadosSecundariosDoUsuarioMongoAppServices,
  loginMongoAppServices,
  logoutMongoAppServices
} from 'src/integracoes/servicos/mongo-atlas/autenticacao';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth';
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  sendPasswordResetEmail: (email: string) => Promise<any>;
  verifyPasswordResetCode: (code: any) => Promise<any>;
  confirmPasswordReset: (code: any, newPassword: any) => Promise<any>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
  verifyPasswordResetCode: () => Promise.resolve(),
  confirmPasswordReset: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({
  children
}: AuthProviderProps) => {
  const dispatch = useDispatch();
  const account = useSelector((state: RootState) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const signInWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<any> => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = (): Promise<any> => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<any> => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const sendPasswordResetEmail = async (email: string): Promise<any> => {
    return firebase.auth().sendPasswordResetEmail(email);
  };

  const verifyPasswordResetCode = async (code: any) => {
    return firebase.auth().verifyPasswordResetCode(code);
  };

  const confirmPasswordReset = async (code: any, newPassword: any) => {
    return firebase.auth().confirmPasswordReset(code, newPassword);
  };

  const autenticaUsuarioNoMongoAppServices = async (usuarioFirebaseIdToken: firebase.auth.IdTokenResult, usuarioFirebaseJwtToken: string, quantChamadasLogin = 0): Promise<void> => {
    try {
      const { empresa } = usuarioFirebaseIdToken.claims;

      /* 
        devido a mudança de arquitura (utilizar o custom_data para acessar os dados
        secundarios dos usuarios, que antes vinham do Firebase)
        um novo atributo fez-se necessario nos documentos da collection users (appServicesId)
        mas os documentos existentes não possuiam esse atributo, o que causa uma falha ao obter o 'context.user.custom_data.privilegeHash'
        por exemplo, acarretando na falha do login, funções ou rules dentro da plataforma
        para resolver o problema criou-se um trigger que adiciona o appServicesId nos documentos que não o possuiam
        mas mesmo após sua inserção é necessário que usuário refaça o processo de login para pegar a versão atualizada dos dados
        */
      const usuarioMongoAppServices = await loginMongoAppServices(usuarioFirebaseJwtToken);
      const dadosSecundarios = await obtemDadosSecundariosDoUsuarioMongoAppServices();

      if (Object.keys(dadosSecundarios).length === 0 && quantChamadasLogin < 2) {
        await logoutMongoAppServices();
        const novaQuantidade = quantChamadasLogin + 1;
        // Reobter os tokens Firebase para garantir que estão atualizados
        const novosTokens = await obtemDadosUsuarioFirebase(firebase.auth().currentUser!);
        autenticaUsuarioNoMongoAppServices(
          novosTokens.usuarioFirebaseIdToken,
          novosTokens.usuarioFirebaseJwtToken,
          novaQuantidade
        );
      } else if (quantChamadasLogin >= 2) {
        const erro = {
          nome: 'MONGO-APPSERVICES-FALHA-LOGIN',
          mensagem: 'Falha ao obter credenciais de acesso ao serviço de dados',
          causa: new Error('A quantidade máxima de tentativas de login no serviço de dados foi excedida')
        };
        throw new FarmaError(erro);
      } else {
        dispatch(
          userDataRequestInit({
            usuarioMongoAppServices,
            empId: empresa
          })
        );
      }
    } catch (e: any) {
      let mensagem;
      if (e instanceof FarmaError) {
        mensagem = e.mensagem;
      } else {
        mensagem = 'Falha ao obter credenciais de acesso ao serviço de dados';
      }
      enqueueSnackbar(mensagem, { variant: 'error' });
      dispatch(
        authStateChanged({
          isAuthenticated: false,
          loading: false,
          user: null,
        })
      );
      history.push('/');
    }
  };

  const logout = (): Promise<void> => {
    dispatch(clearUserAuth());
    dispatch(empresaCleanListEmps());
    return firebase.auth().signOut();
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((usuarioFirebase) => {
      if (usuarioFirebase) {
        obtemDadosUsuarioFirebase(usuarioFirebase).then(({ usuarioFirebaseIdToken, usuarioFirebaseJwtToken }) => {
          autenticaUsuarioNoMongoAppServices(usuarioFirebaseIdToken, usuarioFirebaseJwtToken);
        })
      } else {
        dispatch(
          authStateChanged({
            isAuthenticated: false,
            user: null,
            loading: false
          })
        );
      }
    });

    return unsubscribe;
  }, []);

  const obtemDadosUsuarioFirebase = async (usuarioFirebase: firebase.User) => {
    try {
      const usuarioFirebaseIdToken = await usuarioFirebase.getIdTokenResult(true);
      const usuarioFirebaseJwtToken = await usuarioFirebase.getIdToken(true);

      return { usuarioFirebaseIdToken, usuarioFirebaseJwtToken };
    } catch (e: any) {
      const erro = {
        nome: 'FIREBASE-FALHA-LOGIN',
        mensagem: 'Falha ao obter credenciais de acesso ao serviço de autenticação',
        causa: e
      };
      throw new FarmaError(erro);
    }
  }

  if (!account.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...account,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        sendPasswordResetEmail,
        verifyPasswordResetCode,
        confirmPasswordReset,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
