import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { FarmaError, FarmaFuncaoError } from 'src/integracoes/modelos/erros';
import {
  CREATE_PAINEL_INIT,
  MODIFY_PAINEL_INIT,
  LIST_PAINEL_INIT,
  DELETE_PAINEL_INIT
} from '../actionTypes';
import {
  paineisErrorMsg,
  modifyPainelSuccess,
  createPainelSuccess,
  listPainelSuccess,
  deletePainelSuccess
} from './actions';
import { Paineis } from 'src/integracoes/servicos/mongo-atlas/funcoes/paineis';

// ---------------------------Criar Painel---------------------------------------------------------

const createPainelAsync = async ({ usuarioMongoAppServices, data }) => {
  try {
    const resposta = await Paineis.cria(usuarioMongoAppServices, data);
    if (resposta.status) {
      return {
        isCreated: true,
        isExists: false,
        msg: resposta.mensagem
      };
    } else {
      // ja existe um painel com o nome informado
      return { isCreated: false, isExists: false, msg: resposta.mensagem };
    }
  } catch (error) {
    // ocorreu um erro ao buscar ou inserir um painel
    console.error(error);
    const erro = {
      nome: 'MONGODB-ERRO-DESCONHECIDO',
      mensagem: `${error.mensagem}`,
      causa: error
    };
    throw new FarmaError(erro);
  }
};

function* createPainelMiddle({ payload }) {
  const { usuarioMongoAppServices, data } = payload;
  try {
    const { isCreated, isExists, msg } = yield call(createPainelAsync, {
      usuarioMongoAppServices,
      data
    });
    if (isCreated) {
      yield put(createPainelSuccess({ isCreated, msg: msg }));
    }
    if (!isCreated) {
      if (isExists) {
        yield put(
          paineisErrorMsg({
            msg: msg
          })
        );
      } else {
        yield put(
          paineisErrorMsg({
            msg: msg
          })
        );
      }
    }
  } catch (error) {
    console.error(error);
    yield put(paineisErrorMsg({ msg: error }));
  }
}

export function* watchCreatePainel() {
  yield takeEvery(CREATE_PAINEL_INIT, createPainelMiddle);
}

// -------------------Listar Paineis-----------------------------

const listPainelAsync = async (usuarioMongoAppServices) => {
  try {
    const { status, dados, mensagem } = await Paineis.obtem(usuarioMongoAppServices);
    if (status) {
      return { allPaineis: dados, status };
    }
    return { status, mensagem };
  } catch (error) {
    console.error(error);
    throw new FarmaFuncaoError(error);
  }
};

function* listPainelMiddle({ payload }) {
  const usuarioMongoAppServices = payload;
  try {
    const resposta = yield call(listPainelAsync, usuarioMongoAppServices);
    if (resposta.status) {
      yield put(listPainelSuccess(resposta));
    } else {
      yield put(paineisErrorMsg({ msg: resposta.mensagem }));
    }
  } catch (error) {
    console.error(error);
    yield put(paineisErrorMsg(error));
  }
}

export function* watchListPainel() {
  yield takeEvery(LIST_PAINEL_INIT, listPainelMiddle);
}

// ---------------------Atualizar Painel --------------------------------------------------

export const modifyPainel = async (usuarioMongoAppServices, data) => {
  try {
    const resposta = await Paineis.atualiza(usuarioMongoAppServices, data);
    if (resposta.status === true) {
      return { isUpdated: true, msg: resposta.mensagem };
    } else {
      return { isUpdated: false, msg: resposta.dados.message };
    }
  } catch (error) {
    console.error(error);
    const erro = {
      nome: 'MONGODB-ERRO-DESCONHECIDO',
      mensagem: `${error.mensagem}`,
      causa: error
    };
    throw new FarmaError(erro);
  }
};

function* parseModifyPainel({ payload }) {
  const { usuarioMongoAppServices, data } = payload;
  try {
    const resposta = yield call(modifyPainel, usuarioMongoAppServices, data);
    if (resposta.isUpdated) {
      yield put(
        modifyPainelSuccess({
          isUpdated: resposta.isUpdated,
          msg: resposta.msg
        })
      );
    } else {
      yield put(paineisErrorMsg({ msg: resposta.msg }));
    }
  } catch (error) {
    console.error(error);
    yield put(paineisErrorMsg({ msg: error }));
  }
}

export function* watchModifyPainel() {
  yield takeEvery(MODIFY_PAINEL_INIT, parseModifyPainel);
}

// -------------------------- Deletar Painel --------------------

export const deletePainel = async (usuarioMongoAppServices, data) => {
  let msg = 'Solicitação não Enviada';
  let isDeleted = false;
  try {
    const resposta = await Paineis.deleta(usuarioMongoAppServices, data);
    if (resposta.status === true) {
      msg = resposta.mensagem;
      isDeleted = true;
    } else {
      msg = resposta.mensagem;
      isDeleted = false;
    }
  } catch (error) {
    console.error(error);
    const erro = {
      nome: 'MONGODB-ERRO-DESCONHECIDO',
      mensagem: `${error.mensagem}`,
      causa: error
    };
    throw new FarmaError(erro);
  }
  return { msg, isDeleted };
};

export function* parseDeletePainel({ payload }) {
  const { usuarioMongoAppServices, data } = payload;
  const resposta = yield call(deletePainel, usuarioMongoAppServices, data);
  try {
    if (resposta.isDeleted) {
      yield put(
        deletePainelSuccess({
          isDeleted: resposta.isDeleted,
          msg: resposta.msg
        })
      );
    } else {
      yield put(paineisErrorMsg({ msg: resposta.msg }));
    }
  } catch (error) {
    console.error(error);
    yield put(paineisErrorMsg({ msg: error }));
  }
}

export function* watchDeletePainel() {
  yield takeEvery(DELETE_PAINEL_INIT, parseDeletePainel);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchCreatePainel),
    fork(watchListPainel),
    fork(watchModifyPainel),
    fork(watchDeletePainel)
  ]);
}
