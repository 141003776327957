import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SET_CURVA_ABC_INIT } from '../actionTypes';
import {
  setCurvaAbcSuccess,
  errorCurvaMsg,
} from './actions';
import { IEmpresaCurvaABC, IEmpresaParametroD } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { User as RealmUser } from 'realm-web';

// Atualizar dados comunicacao com o banco -------------------------
const setCurvaAbcAsync = async ({ usuarioMongoAppServices, empId, categorias, parametroD }:
  { usuarioMongoAppServices: RealmUser, empId: string, categorias: IEmpresaCurvaABC[], parametroD: IEmpresaParametroD }) => {
  const { status, mensagem } = await Empresas.atualizaCurvaAbc(usuarioMongoAppServices, empId, categorias, parametroD);
  if (status) {
    return { isUpdated: true, msg: mensagem };
  } else {
    return { isUpdated: false, msg: mensagem };
  }
};

// Atualizar dados funcao chamada pelo dispatch ---------------------
function* setCurvaAbcMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empId, categorias, parametroD } = payload;
  try {
    const { isUpdated, msg } = yield call(setCurvaAbcAsync, {
      usuarioMongoAppServices,
      empId,
      categorias,
      parametroD
    });
    if (isUpdated) {
      yield put(setCurvaAbcSuccess({ msg }));
    } else {
      yield put(errorCurvaMsg({ msg }));
    }
  } catch (error: any) {
    yield put(errorCurvaMsg({ msg: error.mensagem }));
  }
}

export function* handleSetCurvaAbc() {
  yield takeEvery(SET_CURVA_ABC_INIT, setCurvaAbcMiddle);
}

// WATCHS ------------------------------------------------
export default function* rootSaga() {
  yield all([fork(handleSetCurvaAbc)]);
}
