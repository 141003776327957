import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { User as RealmUser } from 'realm-web';
import { IUsuarioFirebase, IUsuarioFormulario } from 'src/integracoes/modelos/usuarios';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { Usuarios } from 'src/integracoes/servicos/mongo-atlas/funcoes/usuarios';
import {
  criaUsuarioFirebase,
  removeUsuarioFirebase,
  obtemUsuariosFirebase
} from '../../integracoes/servicos/firebase/functions';
import {
  USERS_FETCH_DATA_INIT,
  USERS_CREATE_USER_INIT,
  USERS_DELETE_USER_INIT,
  USERS_MODIFY_USER_INIT,
  ATRIBUIR_EMPRESA_INIT
} from '../actionTypes';
import {
  usersFetchDataSuccess,
  usersCreateUserSuccess,
  usersDeleteUserSuccess,
  usersModifyUserSuccess,
  atribuirEmpresaSuccess,
  userErrorMsg
} from './actions';
import { IUsuarioMongoDB } from 'src/integracoes/modelos/usuarios';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { Status } from 'src/constants';

interface FetchListaUsuariosResult {
  isError: boolean;
  usuariosMongo: IUsuarioMongoDB[];
  usuariosFirebase: IUsuarioFirebase[];
  msg: string;
}

// ---------------Busca lista Usuários por empresa --------------------------------------------

const fetchListarUsuarios = async ({ usuarioMongoAppServices, empId }: { usuarioMongoAppServices: RealmUser, empId: string }): Promise<FetchListaUsuariosResult> => {
  try {
    // Fazendo as chamadas ao MongoDB e Firebase simultaneamente
    const [mongoResponse, firebaseResponse] = await Promise.all([
      Empresas.obtemUsuarios(usuarioMongoAppServices, empId),
      obtemUsuariosFirebase(empId)
    ]);

    const { status: mongoStatus, dados: mongoDados, mensagem: mongoMensagem } = mongoResponse;
    const { status: firebaseStatus, dados: firebaseDados, mensagem: firebaseMensagem } = firebaseResponse;

    // Validando respostas
    if (mongoStatus &&
      firebaseStatus &&
      Array.isArray(mongoDados) &&
      Array.isArray(firebaseDados)
    ) {
      return {
        isError: false,
        usuariosMongo: mongoDados,
        usuariosFirebase: firebaseDados,
        msg: mongoMensagem
      };
    }

    // Se qualquer uma das chamadas falhar ou os dados não forem arrays, retorna um erro
    return {
      isError: true,
      usuariosMongo: [],
      usuariosFirebase: [],
      msg: !mongoStatus ? mongoMensagem : firebaseMensagem
    };
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseFetchListaUsuarios({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empId } = payload;
  try {
    const usersData: FetchListaUsuariosResult = yield call(fetchListarUsuarios, { empId, usuarioMongoAppServices });
    if (!usersData.isError) {
      yield put(usersFetchDataSuccess(usersData));
    } else {
      yield put(userErrorMsg({ msg: usersData.msg }));
    }
  } catch (error: any) {
    yield put(userErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchFetchListaUsuarios() {
  yield takeEvery(USERS_FETCH_DATA_INIT, parseFetchListaUsuarios);
}


// ----------------Cria um Usuário-------------------------------------------

export const criarUsuario = async (
  usuarioFirebase: IUsuarioFirebase
): Promise<{
  msg: string;
  user: any;
}> => {
  try {
    let user = {};
    const {
      dados: usuarioRetornoFirebase
    }: any | unknown = await criaUsuarioFirebase(usuarioFirebase);
    user = { ...usuarioFirebase, id: usuarioRetornoFirebase.uid };
    return {
      msg:
        'Usuário criado com sucesso. Um e-mail de ativação foi enviado ao novo usuário',
      user: user
    };
  } catch (e: any) {
    throw new FarmaError(e);
  }
};

function* parseCriaUsuario({ payload }: { payload: any; type: string }) {
  const usuarioFirebase: IUsuarioFirebase = payload.data;
  try {
    const { msg, user } = yield call(criarUsuario, usuarioFirebase);
    yield put(
      usersCreateUserSuccess({
        user,
        msg: msg
      })
    );
  } catch (e: any) {
    yield put(userErrorMsg({ msg: e.mensagem }));
  }
}

export function* watchCreateUser() {
  yield takeEvery(USERS_CREATE_USER_INIT, parseCriaUsuario);
}

// ---------------------Atualizar Usuário--------------------------------------------------

export const atualizarUsuario = async (dados: {
  usuarioNovosDados: IUsuarioFormulario;
  usuarioDadosOriginais: IUsuarioFormulario;
  usuarioMongoAppServices: RealmUser;
}): Promise<{
  msg: string;
}> => {
  const { usuarioNovosDados, usuarioDadosOriginais, usuarioMongoAppServices } = dados;
  try {
    // Atualizando dados do usuario no MongoDB 
    // nao eh necessario atualizar no Firebase pois nao eh possivel alterar o e-mail
    // ou a empresa do usuário uma vez que o mesmo foi criado
    const { mensagem } = await Usuarios.atualiza(
      usuarioMongoAppServices,
      usuarioNovosDados
    );
    return { msg: mensagem };
  } catch (e: any) {
    throw new FarmaError(e);
  }
};

function* parseAtualizaUsuario({ payload }: { payload: any; type: string }) {
  const usuarioNovosDados: IUsuarioFormulario = payload.newData;
  const usuarioDadosOriginais: IUsuarioFormulario = payload.oldData;
  const usuarioMongoAppServices: RealmUser = payload.usuarioMongoAppServices;
  const dados = { usuarioNovosDados, usuarioDadosOriginais, usuarioMongoAppServices };
  try {
    const { msg } = yield call(atualizarUsuario, dados);
    yield put(
      usersModifyUserSuccess({
        msg: msg
      })
    );
  } catch (e: any) {
    yield put(userErrorMsg({ msg: e.mensagem }));
  }
}
export function* watchAtualizaUsuario() {
  yield takeEvery(USERS_MODIFY_USER_INIT, parseAtualizaUsuario);
}

// ----------------Deleta Usuário------------------------------------------
export const deletaUsuario = async (dados: {
  usuarioFormulario: IUsuarioFormulario;
  usuarioMongoAppServices: RealmUser;
}): Promise<{
  msg: string;
}> => {
  const { usuarioFormulario, usuarioMongoAppServices } = dados;
  try {
    let msg: string;

    // Verifica se o usuário só existe no Firebase
    if (usuarioFormulario.status === Status.Criado) {
      // Remove o usuário do Firebase Auth
      const { mensagem }: { mensagem: string } = await removeUsuarioFirebase(usuarioFormulario);
      msg = mensagem;
    } else {
      // Desativa o usuário no MongoDB
      const { status, mensagem: msgMongo }: { status: boolean, mensagem: string } = await Usuarios.deleta(usuarioMongoAppServices, usuarioFormulario);
      if (status) {
        // Remove o usuário do Firebase Auth
        const { mensagem }: { mensagem: string } = await removeUsuarioFirebase(usuarioFormulario);
      }
      msg = msgMongo;
    }
    return { msg };
  } catch (e: any) {
    throw new FarmaError(e);
  }
};

function* parseDeletaUsuario({ payload }: { payload: any; type: string }) {
  const usuarioFormulario: IUsuarioFormulario = payload.data;
  const usuarioMongoAppServices: RealmUser = payload.usuarioMongoAppServices;
  const dados = { usuarioFormulario, usuarioMongoAppServices };
  try {
    const { msg } = yield call(deletaUsuario, dados);
    yield put(usersDeleteUserSuccess({ id: usuarioFormulario.id, msg }));
  } catch (e: any) {
    yield put(userErrorMsg({ msg: e.mensagem }));
  }
}

export function* watchDeletaUsuario() {
  yield takeEvery(USERS_DELETE_USER_INIT, parseDeletaUsuario);
}

// ---------------------Atribuir empresas Usuário--------------------------------------------------
const atribuiEmpresasUsuarioAsync = async ({ usuarioMongoAppServices, usuario }: {
  usuarioMongoAppServices: RealmUser,
  usuario: IUsuarioFormulario
}) => {
  try {
    const resposta = await Usuarios.atribuiEmpresas(
      usuarioMongoAppServices,
      usuario
    );
    if (resposta.status) {
      return { isUpdated: true, msg: resposta.mensagem };
    } else {
      return {
        isUpdated: false,
        msg: resposta.mensagem
      };
    }
  }
  catch (error: any) {
    throw new FarmaError(error);
  }
};

function* parseAtribuiEmpresasUsuario({ payload }: { payload: any, type: any }) {
  const {
    usuarioMongoAppServices,
    usuario
  } = payload;
  try {
    const { isUpdated, msg } = yield call(atribuiEmpresasUsuarioAsync, {
      usuarioMongoAppServices,
      usuario
    });
    if (isUpdated) {
      yield put(atribuirEmpresaSuccess({ msg }));
    } else {
      yield put(userErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(userErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchAtribuEmpresasUsuario() {
  yield takeEvery(ATRIBUIR_EMPRESA_INIT, parseAtribuiEmpresasUsuario);
}

//-----------------------------------------------------------------------
export default function* rootSaga() {
  yield all([
    fork(watchFetchListaUsuarios),
    fork(watchCreateUser),
    fork(watchDeletaUsuario),
    fork(watchAtualizaUsuario),
    fork(watchAtribuEmpresasUsuario)
  ]);
}
