import {
  CREATE_PAINEL_INIT,
  CREATE_PAINEL_SUCCESS,
  DELETE_PAINEL_INIT,
  DELETE_PAINEL_SUCCESS,
  MODIFY_PAINEL_INIT,
  MODIFY_PAINEL_SUCCESS,
  LIST_PAINEL_INIT,
  LIST_PAINEL_SUCCESS,
  PAINEIS_ERROR_MSG,
  PAINEIS_CLEAN_UP
} from '../actionTypes';

const INIT_STATE = {
  allPaineis: [],
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  isError: false,
  loading: false,
  error: '',
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_PAINEL_INIT:
      return { ...state, isCreated: false };
    case CREATE_PAINEL_SUCCESS: {
      const { isCreated, msg } = action.payload;
      return {
        ...state,
        msg,
        isCreated
      };
    }
    case MODIFY_PAINEL_INIT: {
      return {
        ...state,
        isUpdated: false
      };
    }
    case MODIFY_PAINEL_SUCCESS: {
      const { isUpdated, msg } = action.payload;
      return {
        ...state,
        msg,
        isUpdated
      };
    }
    case LIST_PAINEL_INIT:
      return { ...state, loading: true };

    case LIST_PAINEL_SUCCESS: {
      const { allPaineis } = action.payload;
      return {
        ...state,
        allPaineis,
        loading: false,
        error: '',
        msg: ''
      };
    }
    case DELETE_PAINEL_INIT:
      return { ...state, loading: true, error: '' };
    case DELETE_PAINEL_SUCCESS: {
      const { isDeleted, msg } = action.payload;
      return {
        ...state,
        isDeleted: isDeleted,
        msg: msg,
        loading: false,
        error: ''
      };
    }
    case PAINEIS_ERROR_MSG: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg: msg,
        isError: true
      };
    }
    case PAINEIS_CLEAN_UP: {
      return {
        ...state,
        loading: false,
        isCreated: false,
        isDeleted: false,
        isUpdated: false,
        isError: false,
        msg: ''
      };
    }
    default:
      return { ...state };
  }
};
