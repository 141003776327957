import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { SET_PAINEIS_USER_INIT } from '../actionTypes';
import { setPaineisToUserSuccess, painelUserErrorMsg } from './actions';
import { Usuarios } from 'src/integracoes/servicos/mongo-atlas/funcoes/usuarios';

// ------------------------Atribuir Painéis ao usuario -------------------------------

const setPaineisToUserAsync = async ({ usuarioMongoAppServices, userId, paineis }) => {
  try {
    const { status, mensagem } = await Usuarios.atribuiPaineis(usuarioMongoAppServices, userId, paineis);
    return {
      status,
      msg: mensagem
    };
  } catch (error) {
    const erro = {
      nome: 'MONGODB-ERRO-DESCONHECIDO',
      mensagem: error.mensagem ? `${error.mensagem}` : `${error.message}`,
      causa: error
    };
    throw new FarmaError(erro);
  }
};

function* setPaineisToUserMiddle({ payload }) {
  const { usuarioMongoAppServices, userId, paineis } = payload;
  try {
    const { status, msg } = yield call(setPaineisToUserAsync, {
      usuarioMongoAppServices,
      userId,
      paineis
    });
    if (status) {
      yield put(setPaineisToUserSuccess({ msg }));
    } else {
      yield put(
        painelUserErrorMsg({
          msg
        })
      );
    }
  } catch (error) {
    yield put(painelUserErrorMsg({ msg: error }));
  }
}

export function* watchPaineisToUser() {
  yield takeEvery(SET_PAINEIS_USER_INIT, setPaineisToUserMiddle);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchPaineisToUser)
  ]);
}
