import {
  USERS_FETCH_DATA_INIT,
  USERS_FETCH_DATA_SUCCESS,
  USERS_DELETE_USER_INIT,
  USERS_DELETE_USER_SUCCESS,
  USERS_CREATE_USER_INIT,
  USERS_CREATE_USER_SUCCESS,
  USERS_MODIFY_USER_INIT,
  USERS_MODIFY_USER_SUCCESS,
  USERS_CLEAN_UP,
  USERS_CLEAR_DATA_LOGOUT,
  ATRIBUIR_EMPRESA_INIT,
  ATRIBUIR_EMPRESA_SUCCESS,
  USERS_ERROR_MSG
} from '../actionTypes';

export const usersFetchDataInit = (data) => ({
  type: USERS_FETCH_DATA_INIT,
  payload: data
});

export const usersFetchDataSuccess = (data) => ({
  type: USERS_FETCH_DATA_SUCCESS,
  payload: data
});

export const usersDeleteUserInit = (data, usuarioMongoAppServices) => ({
  type: USERS_DELETE_USER_INIT,
  payload: { data, usuarioMongoAppServices }
});

export const usersDeleteUserSuccess = (data) => ({
  type: USERS_DELETE_USER_SUCCESS,
  payload: data
});

export const usersCreateUserInit = (data) => ({
  type: USERS_CREATE_USER_INIT,
  payload: { data }
});

export const usersCreateUserSuccess = (data) => ({
  type: USERS_CREATE_USER_SUCCESS,
  payload: data
});

export const usersModifyUserInit = (newData, oldData, usuarioMongoAppServices) => ({
  type: USERS_MODIFY_USER_INIT,
  payload: { newData, oldData, usuarioMongoAppServices }
});

export const usersModifyUserSuccess = (data) => ({
  type: USERS_MODIFY_USER_SUCCESS,
  payload: data
});

export const usersClearDataLogout = (data) => ({
  type: USERS_CLEAR_DATA_LOGOUT,
  payload: data
});

export const atribuirEmpresaInit = (data) => ({
  type: ATRIBUIR_EMPRESA_INIT,
  payload: data
});

export const atribuirEmpresaSuccess = (data) => ({
  type: ATRIBUIR_EMPRESA_SUCCESS,
  payload: data
});

export const usersCleanUp = (data) => ({
  type: USERS_CLEAN_UP,
  payload: data
});

export const userErrorMsg = (data) => ({
  type: USERS_ERROR_MSG,
  payload: data
});
