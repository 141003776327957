import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CREATE_EMPRESA_INIT,
  LIST_EMPRESA_INIT,
  DELETE_EMPRESA_INIT,
  MODIFY_EMPRESA_INIT,
  ATRIBUIR_PAINEIS_EMPRESA_INIT
} from '../actionTypes';
import {
  createEmpresaSuccess,
  listEmpresaSuccess,
  deleteEmpresaSuccess,
  modifyEmpresaSuccess,
  empresaErrorMsg,
  atribuiPainelEmpresaSuccess
} from './actions';
import { IEmpresa } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';
import { IEmpresaStatusEmpresa } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { User as RealmUser } from 'realm-web';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';

interface FetchListaEmpresasResult {
  isError: boolean;
  empresas: IEmpresa[] | null;
  statusEmpresas: IEmpresaStatusEmpresa[] | null;
  errorMessage: string | null;
}

// -------------------Listar Empresas-----------------------------

const listEmpresaAsync = async ({ usuarioMongoAppServices }: { usuarioMongoAppServices: RealmUser }) => {
  try {
    const { status, dados, mensagem } = await Empresas.obtemEmpresas(usuarioMongoAppServices);
    if (status) {
      return {
        isError: !status,
        empresas: dados.allEmps,
        statusEmpresas: dados.allStatusEmps,
        errorMessage: null
      };
    }
    return {
      isError: true,
      empresas: null,
      statusEmpresas: null,
      errorMessage: mensagem
    };
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* parseFetchListEmpresas({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices } = payload;
  try {
    const resp: FetchListaEmpresasResult = yield call(listEmpresaAsync, { usuarioMongoAppServices });
    if (!resp.isError) {
      yield put(listEmpresaSuccess(resp.empresas, resp.statusEmpresas));
    } else {
      yield put(empresaErrorMsg({ msg: resp.errorMessage }));
    }
  } catch (error: any) {
    yield put(empresaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchListEmpresa() {
  yield takeEvery(LIST_EMPRESA_INIT, parseFetchListEmpresas);
}

// ---------------------------Criar Empresa---------------------------------------------------------

const createEmpresaAsync = async ({ usuarioMongoAppServices, empresa }:
  { usuarioMongoAppServices: RealmUser, empresa: IEmpresa }
) => {
  try {
    const { status, dados, mensagem } = await Empresas.cria(usuarioMongoAppServices, empresa);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* createEmpresaMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empresa } = payload;
  try {
    const { isCreated, msg } = yield call(createEmpresaAsync, {
      usuarioMongoAppServices,
      empresa: empresa
    });
    if (isCreated) {
      yield put(createEmpresaSuccess({ msg }));
    } else {
      yield put(empresaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(empresaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchCreateEmpresa() {
  yield takeEvery(CREATE_EMPRESA_INIT, createEmpresaMiddle);
}

// ---------------------------atualiza Empresa---------------------------------------------------------

const updateEmpresaAsync = async ({ usuarioMongoAppServices, empresa, statusEmpresa, setStatus }:
  { usuarioMongoAppServices: RealmUser, empresa: IEmpresa, statusEmpresa: IEmpresaStatusEmpresa, setStatus: boolean }
) => {
  try {
    const { status, dados, mensagem } = await Empresas.atualiza(usuarioMongoAppServices, empresa, statusEmpresa, setStatus);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* updateEmpresaMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empresa, statusEmpresa, setStatus } = payload;
  try {
    const { isCreated, msg } = yield call(updateEmpresaAsync, {
      usuarioMongoAppServices,
      empresa: empresa,
      statusEmpresa,
      setStatus,
    });
    if (isCreated) {
      yield put(modifyEmpresaSuccess({ msg }));
    } else {
      yield put(empresaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(empresaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchUpdateEmpresa() {
  yield takeEvery(MODIFY_EMPRESA_INIT, updateEmpresaMiddle);
}

// ---------------------------Deletar Empresa---------------------------------------------------------

const deleteEmpresaAsync = async ({ usuarioMongoAppServices, empId }:
  { usuarioMongoAppServices: RealmUser, empId: string }
) => {
  try {
    const { status, dados, mensagem } = await Empresas.deleta(usuarioMongoAppServices, empId);
    if (status) {
      return {
        isDeleted: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isDeleted: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* deleteEmpresaMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empId } = payload;
  try {
    const { isDeleted, msg } = yield call(deleteEmpresaAsync, {
      usuarioMongoAppServices,
      empId
    });
    if (isDeleted) {
      yield put(deleteEmpresaSuccess({ msg }));
    } else {
      yield put(empresaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(empresaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchDeleteEmpresa() {
  yield takeEvery(DELETE_EMPRESA_INIT, deleteEmpresaMiddle);
}

// --------------------- Atribuir paineis a um Empresa --------------------------------------------------
const atribuipaineisEmpresaAsync = async ({ usuarioMongoAppServices, empId, paineis }: {
  usuarioMongoAppServices: RealmUser,
  empId: string,
  paineis: string[]
}) => {
  try {
    const resposta = await Empresas.atribuiPaineis(
      usuarioMongoAppServices,
      empId,
      paineis
    );
    if (resposta.status) {
      return { isUpdated: true, msg: resposta.mensagem };
    } else {
      return {
        isUpdated: false,
        msg: resposta.mensagem
      };
    }
  }
  catch (error: any) {

    throw new FarmaError(error);
  }
};

function* parseAtribuiPaineisEmpresa({ payload }: { payload: any, type: any }) {
  const {
    usuarioMongoAppServices,
    empId,
    paineis
  } = payload;
  try {
    const { isUpdated, msg } = yield call(atribuipaineisEmpresaAsync, {
      usuarioMongoAppServices,
      empId,
      paineis
    });
    if (isUpdated) {
      yield put(atribuiPainelEmpresaSuccess({ msg }));
    } else {
      yield put(empresaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(empresaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchAtribuPaineisEmpresa() {
  yield takeEvery(ATRIBUIR_PAINEIS_EMPRESA_INIT, parseAtribuiPaineisEmpresa);
}

//-----------------------------------------------------------------------
// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchListEmpresa),
    fork(watchCreateEmpresa),
    fork(watchUpdateEmpresa),
    fork(watchDeleteEmpresa),
    fork(watchAtribuPaineisEmpresa)
  ]);
}
