import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  LIST_COLABORADOR_INIT,
  CREATE_COLABORADOR_INIT,
  EDIT_COLABORADOR_INIT
} from '../actionTypes';

import { FarmaError } from 'src/integracoes/modelos/erros';
import { Colaboradores } from 'src/integracoes/servicos/mongo-atlas/funcoes/colaboradores';
import { IColaborador } from 'src/integracoes/servicos/mongo-atlas/modelos/IColaborador';
import { colaboradorErrorMsg, createColaboradorSuccess, editColaboradorSuccess, listColaboradoresSuccess } from './actions';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { User as RealmUser } from 'realm-web';

interface FetchListaColaboradoresResult {
  isError: boolean;
  campanhas: IColaborador[] | null;
  msg: string | null;
}

// -------------------- OBTEM COLABORADORES POR EMPRESA-----------------------------------------

const fetchListarColaboradores = async ({ empId, usuarioMongoAppServices }: { empId: string, usuarioMongoAppServices: RealmUser }) => {
  try {
    const { status, dados, mensagem } = await Empresas.obtemColaboradores(usuarioMongoAppServices, empId);
    if (status) {
      return {
        isError: !status,
        colaboradores: dados,
        msg: mensagem
      };
    }
    return {
      isError: true,
      colaboradores: [],
      msg: mensagem
    };
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseFetchColaboradores({ payload }: { payload: any, type: any }) {
  const { empId, usuarioMongoAppServices } = payload;
  try {
    const colaboradorData: FetchListaColaboradoresResult = yield call(fetchListarColaboradores, { empId, usuarioMongoAppServices });
    if (!colaboradorData.isError) {
      yield put(listColaboradoresSuccess(colaboradorData));
    } else {
      yield put(colaboradorErrorMsg({ msg: colaboradorData.msg }));
    }
  } catch (error: any) {
    yield put(colaboradorErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchFetchColaboradores() {
  yield takeEvery(LIST_COLABORADOR_INIT, parseFetchColaboradores);
}

// ---------------------------Criar Colaborador---------------------------------------------------------

const createColaboradorAsync = async ({ usuarioMongoAppServices, colaborador }:
  { usuarioMongoAppServices: RealmUser, colaborador: IColaborador }
) => {
  try {
    const { status, dados, mensagem } = await Colaboradores.cria(usuarioMongoAppServices, colaborador);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* createColaboradorMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, colaborador } = payload;
  try {
    const { isCreated, msg, data } = yield call(createColaboradorAsync, { usuarioMongoAppServices, colaborador });
    if (isCreated) {
      yield put(createColaboradorSuccess({ current: data, msg }));
    } else {
      yield put(colaboradorErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(colaboradorErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchCreateColaborador() {
  yield takeEvery(CREATE_COLABORADOR_INIT, createColaboradorMiddle);
}

// ---------------------------atualiza Colaborador---------------------------------------------------------

const atualizaColaboradorAsync = async ({ usuarioMongoAppServices, colaborador }:
  { usuarioMongoAppServices: RealmUser, colaborador: IColaborador }
) => {
  try {
    const { status, dados, mensagem } = await Colaboradores.atualiza(usuarioMongoAppServices, colaborador);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* atualizaColaboradorMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, colaborador } = payload;
  try {
    const { isCreated, msg, data } = yield call(atualizaColaboradorAsync, { usuarioMongoAppServices, colaborador });
    if (isCreated) {
      yield put(editColaboradorSuccess({ current: data, msg }));
    } else {
      yield put(colaboradorErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(colaboradorErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchAtualizaColaborador() {
  yield takeEvery(EDIT_COLABORADOR_INIT, atualizaColaboradorMiddle);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchFetchColaboradores),
    fork(watchCreateColaborador),
    fork(watchAtualizaColaborador),
  ]);
}
