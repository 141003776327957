import {
  CREATE_EMPRESA_INIT,
  CREATE_EMPRESA_SUCCESS,
  DELETE_EMPRESA_INIT,
  DELETE_EMPRESA_SUCCESS,
  LIST_EMPRESA_INIT,
  LIST_EMPRESA_SUCCESS,
  MODIFY_EMPRESA_INIT,
  MODIFY_EMPRESA_SUCCESS,
  EMPRESA_CLEAN_UP,
  EMPRESA_ERROR_MSG,
  ATRIBUIR_PAINEIS_EMPRESA_INIT,
  ATRIBUIR_PAINEIS_EMPRESA_SUCCESS,
  EMPRESA_CLEAN_LIST_EMPS
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  error: '',
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  isError: false,
  isReceived: false,
  allEmps: [],
  allStatusEmps: [],
  msg: '',
  isAssign: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_EMPRESA_INIT:
      return { ...state, isCreated: false };
    case CREATE_EMPRESA_SUCCESS: {
      const { msg } = action.payload;
      return { ...state, isCreated: true, msg };
    }
    case DELETE_EMPRESA_INIT:
      return {
        ...state,
        loading: true,
        isDeleted: false
      };
    case DELETE_EMPRESA_SUCCESS: {
      const { msg, empId } = action.payload;
      return {
        ...state,
        isDeleted: true,
        msg,
        allEmps: state.allEmps.filter((elem) => elem.id !== empId),
        loading: false
      };
    }
    case LIST_EMPRESA_INIT:
      return { ...state, loading: true };

    case LIST_EMPRESA_SUCCESS: {
      const { allEmps, allStatusEmps } = action.payload;
      return {
        ...state,
        allEmps,
        allStatusEmps,
        loading: false
      };
    }
    case MODIFY_EMPRESA_INIT: {
      return {
        ...state,
        isUpdated: false
      };
    }
    case MODIFY_EMPRESA_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        msg,
        isUpdated: true
      };
    }

    case EMPRESA_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, msg, isError: true };
    }
    case EMPRESA_CLEAN_UP: {
      return {
        ...state,
        loading: false,
        isCreated: false,
        isDeleted: false,
        isUpdated: false,
        isError: false,
        isReceived: false,
        msg: '',
        isAssign: false
      };
    }
    case ATRIBUIR_PAINEIS_EMPRESA_INIT: {
      return {
        ...state,
        loading: true,
        isAssign: false
      };
    }
    case ATRIBUIR_PAINEIS_EMPRESA_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isAssign: true
      };
    }
    case EMPRESA_CLEAN_LIST_EMPS: {
      return {
        ...state,
        allEmps: []
      };
    }
    default:
      return { ...state };
  }
};
