import {
  USERS_FETCH_DATA_INIT,
  USERS_FETCH_DATA_SUCCESS,
  USERS_DELETE_USER_INIT,
  USERS_DELETE_USER_SUCCESS,
  USERS_CREATE_USER_INIT,
  USERS_CREATE_USER_SUCCESS,
  USERS_MODIFY_USER_INIT,
  USERS_MODIFY_USER_SUCCESS,
  USERS_CLEAN_UP,
  USERS_CLEAR_DATA_LOGOUT,
  ATRIBUIR_EMPRESA_INIT,
  ATRIBUIR_EMPRESA_SUCCESS,
  USERS_ERROR_MSG
} from '../actionTypes';

const INIT_STATE = {
  usuariosMongo: [],
  usuariosFirebase: [],
  loading: false,
  error: false,
  success: false,
  deleted: false,
  updated: false,
  msg: null,
  userCreated: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USERS_FETCH_DATA_INIT: {
      return {
        ...state,
        loading: true
      };
    }
    case USERS_FETCH_DATA_SUCCESS: {
      const { usuariosMongo, usuariosFirebase } = action.payload;
      return {
        ...state,
        usuariosMongo,
        usuariosFirebase,
        loading: false,
        error: false
      };
    }
    case USERS_DELETE_USER_INIT: {
      return {
        ...state,
        loading: true,
        success: false
      };
    }
    case USERS_DELETE_USER_SUCCESS: {
      const { id, msg } = action.payload;
      return {
        ...state,
        data: state.usuariosMongo.filter((elem) => elem.id !== id),
        loading: false,
        error: false,
        deleted: true,
        msg
      };
    }
    case USERS_CREATE_USER_INIT: {
      return {
        ...state,
        loading: true,
        success: false
      };
    }
    case USERS_CREATE_USER_SUCCESS: {
      const { user, msg } = action.payload;
      return {
        ...state,
        data: state.usuariosMongo.concat(user),
        loading: false,
        error: false,
        success: true,
        msg,
        userCreated: user
      };
    }
    case USERS_MODIFY_USER_INIT: {
      return {
        ...state,
        loading: true,
        updated: false
      };
    }
    case USERS_MODIFY_USER_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        msg,
        updated: true
      };
    }
    case ATRIBUIR_EMPRESA_INIT: {
      return {
        ...state,
        loading: true,
        updated: false
      };
    }
    case ATRIBUIR_EMPRESA_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        msg,
        updated: true
      };
    }
    case USERS_CLEAN_UP: {
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        deleted: false,
        updated: false,
        userCreated: {}
      };
    }
    case USERS_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, msg, error: true };
    }
    case USERS_CLEAR_DATA_LOGOUT: {
      return {
        ...INIT_STATE
      };
    }
    default:
      return { ...state };
  }
};
