import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  LIST_CATEGORIA_INIT,
  CREATE_CATEGORIA_INIT,
  DELETE_CATEGORIA_INIT,
  UPDATE_CATEGORIA_INIT
} from '../actionTypes';
import { Categorias } from 'src/integracoes/servicos/mongo-atlas/funcoes/categorias';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { ICategoria } from 'src/integracoes/servicos/mongo-atlas/modelos/ICategoria';
import { categoriaErrorMsg, createCategoriaPainelSuccess, deleteCategoriaPainelSuccess, listCategoriaPainelSuccess, updateCategoriaPainelSuccess } from './actions';
import { User as RealmUser } from 'realm-web';

interface FetchListaCategoriasResult {
  isError: boolean;
  categorias: ICategoria[] | null;
  errorMessage: string | null;
}

// -------------------Listar Categorias Paineis-----------------------------

const listarCategoriasPainel = async ({ usuarioMongoAppServices }: { usuarioMongoAppServices: RealmUser }) => {
  try {
    const { status, dados, mensagem } = await Categorias.obtemCategorias(usuarioMongoAppServices);
    if (status) {
      return {
        isError: !status,
        categorias: dados,
        errorMessage: null
      };
    }
    return {
      isError: true,
      categorias: null,
      errorMessage: mensagem
    };
  } catch (error: any) {

    throw new FarmaError(error);
  }
};

function* parseFetchListaCategoriasPainel({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices } = payload;
  try {
    const resp: FetchListaCategoriasResult = yield call(listarCategoriasPainel, { usuarioMongoAppServices });
    if (!resp.isError) {
      yield put(listCategoriaPainelSuccess(resp.categorias));
    } else {
      yield put(categoriaErrorMsg({ msg: resp.errorMessage }));
    }
  } catch (error: any) {
    yield put(categoriaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchListarCategoriasPainel() {
  yield takeEvery(LIST_CATEGORIA_INIT, parseFetchListaCategoriasPainel);
}


// ---------------------------Criar Categoria---------------------------------------------------------

const createCategoriaAsync = async ({ usuarioMongoAppServices, categoria }:
  { usuarioMongoAppServices: RealmUser, categoria: ICategoria }
) => {
  try {
    const { status, dados, mensagem } = await Categorias.cria(usuarioMongoAppServices, categoria);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* createCategoriaMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, categoria } = payload;
  try {
    const { isCreated, msg, data } = yield call(createCategoriaAsync, {
      usuarioMongoAppServices,
      categoria: categoria
    });
    if (isCreated) {
      yield put(createCategoriaPainelSuccess({ msg }));
    } else {
      yield put(categoriaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(categoriaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchCreateCategoria() {
  yield takeEvery(CREATE_CATEGORIA_INIT, createCategoriaMiddle);
}

// ---------------------------atualiza Categoria---------------------------------------------------------

const atualizaCategoriaAsync = async ({ usuarioMongoAppServices, categoria }:
  { usuarioMongoAppServices: RealmUser, categoria: ICategoria }
) => {
  try {
    const { status, dados, mensagem } = await Categorias.atualiza(usuarioMongoAppServices, { id: categoria.id, name: categoria.name });
    if (status) {
      return {
        isUpdated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isUpdated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* atualizaCategoriaMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, categoria } = payload;
  try {
    const { isUpdated, msg } = yield call(atualizaCategoriaAsync, {
      usuarioMongoAppServices,
      categoria: categoria
    });
    if (isUpdated) {
      yield put(updateCategoriaPainelSuccess({ msg }));
    } else {
      yield put(categoriaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(categoriaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchAtualizaCategoria() {
  yield takeEvery(UPDATE_CATEGORIA_INIT, atualizaCategoriaMiddle);
}

// ---------------------------Deletar Categoria---------------------------------------------------------

const deleteCategoriaAsync = async ({ usuarioMongoAppServices, categoria }:
  { usuarioMongoAppServices: RealmUser, categoria: ICategoria }
) => {
  try {
    const { status, dados, mensagem } = await Categorias.deleta(usuarioMongoAppServices, { id: categoria.id, name: categoria.name });
    if (status) {
      return {
        isDeleted: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isDeleted: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* deleteCategoriaMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, categoria } = payload;
  try {
    const { isDeleted, msg, data } = yield call(deleteCategoriaAsync, {
      usuarioMongoAppServices,
      categoria: categoria
    });
    if (isDeleted) {
      yield put(deleteCategoriaPainelSuccess({ msg }));
    } else {
      yield put(categoriaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(categoriaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchDeleteCategoria() {
  yield takeEvery(DELETE_CATEGORIA_INIT, deleteCategoriaMiddle);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchListarCategoriasPainel),
    fork(watchCreateCategoria),
    fork(watchDeleteCategoria),
    fork(watchAtualizaCategoria)
  ]);
}
