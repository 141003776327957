import {
  SET_PAINEIS_USER_INIT,
  SET_PAINEIS_USER_SUCCESS,
  PAINEL_USER_ERROR_MSG,
  PAINEL_USER_CLEAN_UP
} from '../actionTypes';

export const setPaineisToUserInit = (usuarioMongoAppServices, userId, paineis) => ({
  type: SET_PAINEIS_USER_INIT,
  payload: { usuarioMongoAppServices, userId, paineis }
});

export const setPaineisToUserSuccess = ({ msg }) => ({
  type: SET_PAINEIS_USER_SUCCESS,
  payload: { msg }
});

export const painelUserErrorMsg = (msg) => ({
  type: PAINEL_USER_ERROR_MSG,
  payload: msg
});

export const painelClearStatus = (data) => ({
  type: PAINEL_USER_CLEAN_UP,
  payload: data
});

