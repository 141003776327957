import {
  CREATE_PAINEL_INIT,
  CREATE_PAINEL_SUCCESS,
  MODIFY_PAINEL_INIT,
  MODIFY_PAINEL_SUCCESS,
  DELETE_PAINEL_INIT,
  DELETE_PAINEL_SUCCESS,
  LIST_PAINEL_INIT,
  LIST_PAINEL_SUCCESS,
  PAINEIS_ERROR_MSG,
  PAINEIS_CLEAN_UP
} from '../actionTypes';

export const createPainelInit = (usuarioMongoAppServices, data) => ({
  type: CREATE_PAINEL_INIT,
  payload: { usuarioMongoAppServices, data }
});

export const createPainelSuccess = ({ isCreated, msg }) => ({
  type: CREATE_PAINEL_SUCCESS,
  payload: { isCreated, msg }
});

export const modifyPainelInit = (usuarioMongoAppServices, data) => ({
  type: MODIFY_PAINEL_INIT,
  payload: { usuarioMongoAppServices, data }
});

export const modifyPainelSuccess = ({ isUpdated, msg }) => ({
  type: MODIFY_PAINEL_SUCCESS,
  payload: { isUpdated, msg }
});

export const deletePainelInit = (usuarioMongoAppServices, data) => ({
  type: DELETE_PAINEL_INIT,
  payload: { usuarioMongoAppServices, data }
});

export const deletePainelSuccess = ({ isDeleted, msg }) => ({
  type: DELETE_PAINEL_SUCCESS,
  payload: { isDeleted, msg }
});

export const listPainelInit = (usuarioMongoAppServices) => ({
  type: LIST_PAINEL_INIT,
  payload: usuarioMongoAppServices
});

export const listPainelSuccess = (data) => {
  return {
    type: LIST_PAINEL_SUCCESS,
    payload: data
  };
};

export const paineisErrorMsg = (msg) => ({
  type: PAINEIS_ERROR_MSG,
  payload: msg
});

export const paineisCleanUp = (data) => ({
  type: PAINEIS_CLEAN_UP,
  payload: data
});
